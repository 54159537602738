import React from "react";

const Image = ({ loading, onError, ...props }) => (
    <img
        {...props}
        loading={loading || "lazy"}
        onError={
            onError
                ? onError
                : (e) => (e.target.src = "/assets/no-image-found-360x260.png")
        }
    />
);

export default Image;
