/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *       CdnImage component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Image from "../Image";
import { getCdnBasePath, getCdnUrl } from "../../support/helpers";

const CdnImage = ({ src, isGlobalImage = false, ...props }) => {
    const styling = useSelector((state) => state.root.styling);
    const { host: rawHost } = useLocation();
    let host,
        cdnUrl = "";
    if (!src.includes("http")) {
        if (rawHost) host = getCdnUrl(rawHost);
        cdnUrl = getCdnBasePath(
            !isGlobalImage && styling?.nicheDomain2ndLevel,
            host
        );
    }
    return <Image {...props} src={`${cdnUrl}${src}`} />;
};

export default CdnImage;
