import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTrackEvent } from "../../../../hooks/useTrackEvent";
import { toast } from "react-toastify";
import Toast from "../../Toast";
import {
    useDeleteUserPropertyMutation,
    useGetUserQuery,
    useSaveUserPropertyMutation,
} from "../../../../services/userEndpoints";
import { useDebounce } from "../../../../hooks/useDebounce";

/**
 * Handles the save and unsave logic for the save property button on
 * listing cards and the listing details page.
 *
 * @param {string} listingNumber - the listing number
 * @param {string} location - current location (listing card/listing details)
 * @param {boolean} shouldSaveProperty= - whether or not to save the property
 * @param {Function} setShouldSaveProperty - callback to set shouldSaveProperty to false
 * @param {number} debounceDelay - delay in ms before action is taken
 * @returns {{isSaved: boolean, handleClick: Function}} - isSaved and handleClick
 */
export const useAuthedUser = ({
    listingNumber,
    location,
    shouldSaveProperty,
    setShouldSaveProperty,
    debounceDelay = 300,
}) => {
    const [isSaved, setIsSaved] = useState(false);
    const fetchUserOnce = useRef(true); // We only want to fetch the user once to prevent the user call on each save
    const userId = useSelector((state) => state.root.userSession.userID);
    const [saveProperty, { isError: isSaveError, error }] =
        useSaveUserPropertyMutation();
    const [deleteProperty, { isError: isDeleteError }] =
        useDeleteUserPropertyMutation();
    const { data: userData = {} } = useGetUserQuery(
        { userId },
        { skip: !userId || !fetchUserOnce.current }
    );
    const userProperties =
        userData?.userInfo?.dIAccountInfo?.savedProperties?.userProperties;
    const preventInitialSave = useRef();
    const debouncedInputValue = useDebounce(isSaved, debounceDelay);
    const isError = isSaveError || isDeleteError;
    const toastId = listingNumber;
    const { trackGTM } = useTrackEvent();

    const handleSaveOrDelete = () => {
        const action = isSaved ? saveProperty : deleteProperty;
        action({ userId, listingNumber });
        trackClick();
        displayToast(isSaved, listingNumber);
    };

    // This will set the initial isSaved state
    useEffect(() => {
        if (Array.isArray(userProperties)) {
            const globalIsSaved = userProperties.includes(listingNumber);
            if (globalIsSaved !== isSaved) {
                setIsSaved(globalIsSaved);
            }
            if (userId) {
                fetchUserOnce.current = false;
            }
        }
    }, [userData, listingNumber]);

    // Debouncing the save action. This prevents a spam click and will only save once
    // The user has stopped clicking for 300ms
    useEffect(() => {
        if (preventInitialSave.current) {
            const globalIsSaved = (userProperties || []).includes(
                listingNumber
            );
            if (globalIsSaved !== debouncedInputValue) {
                handleSaveOrDelete();
            }
        }
        preventInitialSave.current = true;
    }, [debouncedInputValue]);

    // Handle an error reponse from a save request
    useEffect(() => {
        if (isError) {
            setIsSaved(!isSaved);
            const isLimitExceeded =
                error?.data?.message?.error?.includes("limit");
            const content = isLimitExceeded
                ? "We are unable to save this property. Please remove properties from your Saved Properties to continue."
                : "Sorry, We we ran into an issue. Please try again later.";
            const toastData = {
                render: <Toast content={content} />,
                type: isLimitExceeded ? toast.TYPE.WARNING : toast.TYPE.ERROR,
                icon: false,
                autoClose: 10000,
            };

            if (toast.isActive(toastId)) {
                toast.update(toastId, toastData);
            } else {
                toast(<Toast content={content} />, toastData);
            }
        }
    }, [error, isError]);

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (shouldSaveProperty && Array.isArray(userProperties)) {
            setIsSaved(!isSaved);
            setShouldSaveProperty(false);
        }
    }, [shouldSaveProperty, userData]);

    /**
     * Tracks the click event in Google Analytics.
     */
    const trackClick = () => {
        trackGTM({
            event: `buttonClick`,
            action: "click",
            type: "Save Property",
            category: "user_action",
            value: isSaved ? "delete" : "save",
            location,
            listingNumber,
        });
    };

    const handleClick = () => {
        setIsSaved(!isSaved);
    };

    return {
        isSaved,
        handleClick,
    };
};

/**
 * Displays a toast message indicating if a property was saved or removed.
 * @param {boolean} isSaved - A boolean indicating whether the property was saved or removed.
 * @param {string} toastId - The ID of the toast message.
 */
const displayToast = (isSaved, toastId) => {
    // dismiss any previous toast to prevent crazy toast stacking
    toast.dismiss();
    const toastContent = isSaved
        ? "Property Saved!"
        : "Saved Property Removed.";
    toast.success(<Toast content={toastContent} />, {
        icon: false,
        toastId,
        autoClose: 2000,
    });
};
