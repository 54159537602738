import React from "react";
import { Button } from "reactstrap";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useAuthedUser } from "./hooks/useAuthedUser";

/**
 * This component will render an empty heart button for anonymous users.
 * When clicked it will prompt the user to log in.
 *
 * @param children {React.ReactNode} - The content of the button
 * @param heartClassName {string} - The class name of the heart icon
 * @param props {object} - Other props to pass to the button
 * @param shouldSaveProperty {boolean} - A boolean indicating whether the user should save the property
 * @param setShouldSaveProperty {Function} - A function that sets whether the user should save the property
 * @param listingNumber {string} - The number of the property to be saved
 * @param location {string} - The location of the property to be saved
 * @returns {React.ReactElement}
 */
const AuthedUser = ({
    children,
    heartClassName = "",
    shouldSaveProperty,
    setShouldSaveProperty,
    listingNumber,
    location,
    ...props
}) => {
    const { isSaved, handleClick } = useAuthedUser({
        listingNumber,
        location,
        shouldSaveProperty,
        setShouldSaveProperty,
    });
    const Heart = isSaved ? FaHeart : FaRegHeart;

    return (
        <Button {...props} onClick={() => handleClick()}>
            <Heart
                className={heartClassName}
                style={{
                    filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 4px)",
                }}
            />
            {children && children(isSaved)}
        </Button>
    );
};

export default AuthedUser;
